import { Flex } from 'components/box'
import Img from 'gatsby-image'
import React from 'react'

const Emoji = props => <Flex as={Img} {...props} />

Emoji.defaultProps = {
  flexGrow: 0,
  flexShrink: 0,
}

export default Emoji
