import Animated, { Revealed } from 'components/animated'
import Box, { Card, Content, Flex, Grid, Section } from 'components/box'
import Button, { SecondaryButton } from 'components/button'
import CallToAction from 'components/sections/cta'
import convertImageMap from 'helpers/convertImageMap'
import Emoji from 'components/emoji'
import IO from 'components/io'
import Layout from 'components/layout'
import Products from 'components/sections/products'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Screenshot from 'components/screenshot'
import Text, { Caps, Heading, Lead } from 'components/text'
import { AppContext } from 'store/app'
import { graphql } from 'gatsby'
import { Square } from 'components/icon'

const Launcher = ({ data }) => {
  const { focusSubscriptionForm } = useContext(AppContext).appState
  const scrollToLearnMore = () =>
    document.querySelector('#intro').scrollIntoView({ behavior: 'smooth' })
  const { page } = data
  const icons = convertImageMap(page.icons)
  const images = convertImageMap(page.images)
  const screens = convertImageMap(page.screens)

  return (
    <Layout pageTitle={page.title}>
      <Section hero>
        <Content my={5}>
          <Grid columns={[1, 1, 2]} gridGap={[4, 4, 0]}>
            <Box
              gridRow={[2, 2, 'auto']}
              textAlign={['center', 'center', 'left']}
            >
              <Caps
                as={Revealed}
                alignItems="center"
                color="blue"
                display="inline-flex"
                fontSize={[5, 4]}
                ml={[-3, -3, 0]}
                my={3}
              >
                <Square mr={3} opacity={0.5} />
                {page.title}
              </Caps>
              <Heading
                as={Revealed}
                forwardedAs="h1"
                delay={1}
                level={0}
                measure="tight"
                mx={['auto', 'auto', 'initial']}
              >
                {page.tagline}
              </Heading>
              <Lead
                as={Revealed}
                forwardedAs="p"
                delay={2}
                fontWeight="medium"
                measure="narrow"
                mb={5}
                mt={3}
                mx={['auto', 'auto', 'initial']}
              >
                {page.description}
              </Lead>
              <Revealed
                containerProps={{ display: 'inline-flex', mb: 2, mr: 2 }}
                delay={4}
              >
                <Button
                  onClick={focusSubscriptionForm}
                  color="blue"
                  size="large"
                >
                  Join Beta
                </Button>
              </Revealed>
              <Revealed containerProps={{ display: 'inline-flex' }} delay={5}>
                <SecondaryButton
                  onClick={scrollToLearnMore}
                  color="blue"
                  size="large"
                >
                  Learn more
                </SecondaryButton>
              </Revealed>
            </Box>
            <Flex
              alignItems="center"
              justifyContent="center"
              position="relative"
              px={3}
            >
              <Revealed
                as={Square}
                bottom="20%"
                color="blue-050"
                duration={6}
                left="5%"
                position="absolute"
                preserveAspectRatio="xMinYMax meet"
                size="200%"
                zIndex={-1}
              />
              <Screenshot
                as={Revealed}
                aspectRatio="16:9"
                borderRadius={3}
                containerProps={{
                  maxWidth: 'narrow',
                  width: '100%',
                }}
                delay={4}
                duration={8}
                screen={screens['Essentials']}
              />
            </Flex>
          </Grid>
        </Content>
      </Section>

      <Section id="intro" display="flex" justifyContent="center">
        <Content p={0}>
          <Box
            as={Revealed}
            backgroundImage={`url(${images['Background'].fluid.src})`}
            backgroundSize="cover"
            bg="blue-025"
            borderRadius={3}
            duration={6}
            style={{
              backgroundBlendMode: 'screen',
            }}
          >
            <Box
              backgroundImage={`url(${images['Background'].fluid.src})`}
              backgroundSize="cover"
              bg="blue"
              color="white"
              p={[4, 5]}
              style={{
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              <Heading level={2} measure="normal">
                The Goal
              </Heading>
              <Lead fontWeight="medium" measure="normal" mt={2}>
                To encourage using the smartphone as a tool by adding varying
                levels of <strong>friction</strong> in launching essential and
                distracting apps.
              </Lead>
              <Text
                as="p"
                color="neutral-500"
                fontSize={4}
                measure="wide"
                mt={4}
              >
                Creating a folder with a <em>&quot;don't touch&quot;</em> label
                doesn 't work. Simply turning the display into grayscale also
                doesn 't cut it.
              </Text>
              <Text
                as="p"
                color="neutral-500"
                fontSize={4}
                measure="wide"
                mt={3}
              >
                To do this, we started from scratch and reimagined how an
                intentional app launcher should look and function.
              </Text>
            </Box>
          </Box>
        </Content>
      </Section>

      <Section>
        <Content my={[5, 5, 6]}>
          <Box>
            <Heading
              as={Revealed}
              forwardedAs="h2"
              level={2}
              mt={4}
              textAlign="center"
            >
              How it works
            </Heading>
            <Lead
              as={Revealed}
              forwardedAs="p"
              delay={1}
              mt={1}
              textAlign="center"
            >
              Organize your apps in three categories
            </Lead>
          </Box>
          <IO threshold={0}>
            {({ isVisible, hasBeenVisible }) => (
              <Grid columns={[1, 1, 3]} gridGap={[5, 5, 4]} mt={5}>
                <Animated
                  delay={1}
                  duration={5}
                  isVisible={isVisible || hasBeenVisible}
                  position="relative"
                >
                  <Box
                    width="100%"
                    maxWidth="narrow"
                    mx="auto"
                    textAlign="center"
                  >
                    <Screenshot
                      aspectRatio="16:9"
                      borderRadius={3}
                      screen={screens['Essentials']}
                    />
                  </Box>
                  <Box px={3} py={4}>
                    <Heading level={4} lineHeight={2} textAlign="center">
                      Essentials
                    </Heading>
                    <Text
                      color="neutral-400"
                      as="p"
                      mt={1}
                      measure="normal"
                      mx="auto"
                      textAlign="center"
                    >
                      A <strong>sortable</strong> list of apps that should be
                      limited to tools that you want easy access with;
                      preferably the ones you use daily.
                    </Text>
                  </Box>
                </Animated>
                <Animated
                  delay={2}
                  duration={5}
                  isVisible={isVisible || hasBeenVisible}
                  position="relative"
                >
                  <Box
                    width="100%"
                    maxWidth="narrow"
                    mx="auto"
                    textAlign="center"
                  >
                    <Screenshot
                      aspectRatio="16:9"
                      borderRadius={3}
                      screen={screens['All Apps']}
                    />
                  </Box>
                  <Box px={3} py={4}>
                    <Heading level={4} lineHeight={2} textAlign="center">
                      All Apps
                    </Heading>
                    <Text
                      color="neutral-400"
                      as="p"
                      mt={1}
                      measure="normal"
                      mx="auto"
                      textAlign="center"
                    >
                      An <strong>alphabetical</strong> list for the apps you use
                      every once in a while.
                    </Text>
                  </Box>
                </Animated>
                <Animated
                  delay={3}
                  duration={5}
                  isVisible={isVisible || hasBeenVisible}
                  position="relative"
                >
                  <Box
                    width="100%"
                    maxWidth="narrow"
                    mx="auto"
                    textAlign="center"
                  >
                    <Screenshot
                      aspectRatio="16:9"
                      borderRadius={3}
                      screen={screens['Distractions']}
                    />
                  </Box>
                  <Box px={3} py={4}>
                    <Heading level={4} lineHeight={2} textAlign="center">
                      Distractions
                    </Heading>
                    <Text
                      color="neutral-400"
                      as="p"
                      mt={1}
                      measure="normal"
                      mx="auto"
                      textAlign="center"
                    >
                      A <strong>randomly sorted</strong> list of apps you prefer
                      to avoid. This panel can be hidden based on a set
                      schedule.
                    </Text>
                  </Box>
                </Animated>
              </Grid>
            )}
          </IO>
        </Content>
      </Section>

      <Section bg="neutral-050">
        <Content my={[5, 5, 6]}>
          <Heading
            as={Revealed}
            forwardedAs="h2"
            level={2}
            measure="wide"
            mt={4}
          >
            Features
          </Heading>
          <Lead as={Revealed} forwardedAs="p" delay={1} measure="wide" mt={1}>
            Here are some of the things you'll love about Intent Launcher.
          </Lead>
          <IO>
            {({ isVisible, hasBeenVisible }) => (
              <Grid
                columns={[1, 2, 4]}
                gridGap={[2, 2, 3]}
                mt={[4, 4, 5]}
                textAlign={['left', 'center']}
              >
                <Animated
                  as={Card}
                  delay={0}
                  display="flex"
                  elevation={1}
                  flexDirection={['row', 'column']}
                  alignItems="center"
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Emoji
                    alignSelf={['flex-start', 'center']}
                    size="emoji"
                    m={3}
                    mb={[3, -2]}
                    mr={[-2, 3]}
                    p={3}
                    {...icons['Letters']}
                  />
                  <Flex flexDirection="column" p={3}>
                    <Heading level={4} lineHeight={2}>
                      Text-only
                    </Heading>
                    <Text color="neutral-400" as="p" mt={1}>
                      No flashy icons trying to get your attention. This is a
                      small first step to clarify your intention.
                    </Text>
                  </Flex>
                </Animated>
                <Animated
                  as={Card}
                  delay={1}
                  display="flex"
                  elevation={1}
                  flexDirection={['row', 'column']}
                  alignItems="center"
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Emoji
                    alignSelf={['flex-start', 'center']}
                    size="emoji"
                    m={3}
                    mb={[3, -2]}
                    mr={[-2, 3]}
                    p={3}
                    {...icons['Point']}
                  />
                  <Flex flexDirection="column" p={3}>
                    <Heading level={4} lineHeight={2}>
                      Gestures
                    </Heading>
                    <Text color="neutral-400" as="p" mt={1}>
                      Swipe horizontally to categorize, drag and drop to sort,
                      touch and hold to get more info. Easy.
                    </Text>
                  </Flex>
                </Animated>
                <Animated
                  as={Card}
                  delay={2}
                  display="flex"
                  elevation={1}
                  flexDirection={['row', 'column']}
                  alignItems="center"
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Emoji
                    alignSelf={['flex-start', 'center']}
                    size="emoji"
                    m={3}
                    mb={[3, -2]}
                    mr={[-2, 3]}
                    p={3}
                    {...icons['Lightning']}
                  />
                  <Flex flexDirection="column" p={3}>
                    <Heading level={4} lineHeight={2}>
                      Android actions
                    </Heading>
                    <Text color="neutral-400" as="p" mt={1}>
                      Touching and holding shows additional app shortcuts
                      introduced in Android Pie.
                    </Text>
                  </Flex>
                </Animated>
                <Animated
                  as={Card}
                  delay={3}
                  display="flex"
                  elevation={1}
                  flexDirection={['row', 'column']}
                  alignItems="center"
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Emoji
                    alignSelf={['flex-start', 'center']}
                    size="emoji"
                    m={3}
                    mb={[3, -2]}
                    mr={[-2, 3]}
                    p={3}
                    {...icons['Key']}
                  />
                  <Flex flexDirection="column" p={3}>
                    <Heading level={4} lineHeight={2}>
                      Built-in Privacy
                    </Heading>
                    <Text color="neutral-400" as="p" mt={1}>
                      No usage tracking, even network requests. All your data is
                      stored on your device.
                    </Text>
                  </Flex>
                </Animated>
              </Grid>
            )}
          </IO>
        </Content>
      </Section>

      <Section>
        <Content my={[5, 5, 6]}>
          <Heading
            as={Revealed}
            forwardedAs="h2"
            level={2}
            measure="wide"
            mt={4}
          >
            Premium Features
          </Heading>
          <Lead as={Revealed} forwardedAs="p" delay={1} measure="wide" mt={1}>
            We also crafted premium features if you want additional control.
          </Lead>
          <IO>
            {({ isVisible, hasBeenVisible }) => (
              <Grid
                columns={[1, 2, 4]}
                gridGap={[2, 2, 3]}
                mt={[4, 4, 5]}
                textAlign={['left', 'center']}
              >
                <Animated
                  as={Card}
                  delay={0}
                  display="flex"
                  elevation={1}
                  flexDirection={['row', 'column']}
                  alignItems="center"
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Emoji
                    alignSelf={['flex-start', 'center']}
                    size="emoji"
                    m={3}
                    mb={[3, -2]}
                    mr={[-2, 3]}
                    p={3}
                    {...icons['Widgets']}
                  />
                  <Flex flexDirection="column" p={3}>
                    <Heading level={4} lineHeight={2}>
                      Widgets
                    </Heading>
                    <Text color="neutral-400" as="p" mt={1}>
                      Minimalist widgets for your Home screen. (countdown,
                      battery, connectivity, etc.)
                    </Text>
                  </Flex>
                </Animated>
                <Animated
                  as={Card}
                  delay={1}
                  display="flex"
                  elevation={1}
                  flexDirection={['row', 'column']}
                  alignItems="center"
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Emoji
                    alignSelf={['flex-start', 'center']}
                    size="emoji"
                    m={3}
                    mb={[3, -2]}
                    mr={[-2, 3]}
                    p={3}
                    {...icons['Moon']}
                  />
                  <Flex flexDirection="column" p={3}>
                    <Heading level={4} lineHeight={2}>
                      Dark mode
                    </Heading>
                    <Text color="neutral-400" as="p" mt={1}>
                      Including an option to change the color mode based on the
                      time of day.
                    </Text>
                  </Flex>
                </Animated>
                <Animated
                  as={Card}
                  delay={2}
                  display="flex"
                  elevation={1}
                  flexDirection={['row', 'column']}
                  alignItems="center"
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Emoji
                    alignSelf={['flex-start', 'center']}
                    size="emoji"
                    m={3}
                    mb={[3, -2]}
                    mr={[-2, 3]}
                    p={3}
                    {...icons['Tools']}
                  />
                  <Flex flexDirection="column" p={3}>
                    <Heading level={4} lineHeight={2}>
                      Advanced Controls
                    </Heading>
                    <Text color="neutral-400" as="p" mt={1}>
                      Hide the distraction panel based on battery level,
                      connected wireless network or location.
                    </Text>
                  </Flex>
                </Animated>
                <Animated
                  as={Card}
                  delay={3}
                  display="flex"
                  elevation={1}
                  flexDirection={['row', 'column']}
                  alignItems="center"
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Emoji
                    alignSelf={['flex-start', 'center']}
                    size="emoji"
                    m={3}
                    mb={[3, -2]}
                    mr={[-2, 3]}
                    p={3}
                    {...icons['Palette']}
                  />
                  <Flex flexDirection="column" p={3}>
                    <Heading level={4} lineHeight={2}>
                      Customization
                    </Heading>
                    <Text color="neutral-400" as="p" mt={1}>
                      Minimal doesn't mean boring. Change the font or match the
                      theme to your phone color.
                    </Text>
                  </Flex>
                </Animated>
              </Grid>
            )}
          </IO>
        </Content>
      </Section>
      <CallToAction />
      <Products />
    </Layout>
  )
}

Launcher.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Launcher

export const query = graphql`
  query LauncherPage {
    page: launcherJson {
      title
      tagline
      description
      icons {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      images {
        title
        image {
          childImageSharp {
            fluid(toFormat: JPG, jpegProgressive: true) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      screens {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
